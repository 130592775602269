var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isLoading)?_c('div',[(
        _vm.userQuotables.filter(
          function (userQuotable) { return userQuotable.saved_for_later === 0; }
        ).length !== 0
      )?_c('div',[(_vm.storefrontStorefront.type === 'b2b')?_c('UserQuotablesQuoteTable',{attrs:{"userQuotables":_vm.userQuotables.filter(
            function (userQuotable) { return userQuotable.saved_for_later === 0; }
          ),"isLoading":_vm.isLoading,"editable":_vm.editable}}):_vm._e(),(_vm.storefrontStorefront.type === 'redeem')?_c('UserQuotablesRedeemTable',{attrs:{"userQuotables":_vm.userQuotables.filter(
            function (userQuotable) { return userQuotable.saved_for_later === 0; }
          ),"isLoading":_vm.isLoading,"editable":_vm.editable}}):_vm._e()],1):_c('div',[_vm._v("No se han agregado productos al carrito")]),(!_vm.hideSavedForLater)?_c('div',[_c('div',{staticClass:"uk-text-large"},[_vm._v("Guardados para después")]),(
          _vm.userQuotables.filter(
            function (userQuotable) { return userQuotable.saved_for_later === 1; }
          ).length !== 0
        )?_c('div',[(_vm.storefrontStorefront.type === 'b2b')?_c('UserQuotablesQuoteTable',{attrs:{"userQuotables":_vm.userQuotables.filter(
              function (userQuotable) { return userQuotable.saved_for_later === 1; }
            ),"isLoading":_vm.isLoading,"editable":_vm.editable}}):_vm._e(),(_vm.storefrontStorefront.type === 'redeem')?_c('UserQuotablesRedeemTable',{attrs:{"userQuotables":_vm.userQuotables.filter(
              function (userQuotable) { return userQuotable.saved_for_later === 1; }
            ),"isLoading":_vm.isLoading,"editable":_vm.editable}}):_vm._e()],1):_c('div',{attrs:{"test":"no-products-saved-for-later"}},[_vm._v(" No se han guardado productos para después ")])]):_vm._e()]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"uk-text-center"},[_c('div',{attrs:{"uk-spinner":""}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }